import React from "react";
import {useSignedIn} from "./data/firebase";

function SignUp() {
  const isSignedIn = useSignedIn();
  return (
    <a className="button" href="/profile">
      {isSignedIn ? "My Shipments" : "Get started today"}
    </a>
  );
}

export default SignUp;
