import React, { useState } from "react";
import { navigate} from "@reach/router"
import {isUserInitDone, useSignedIn} from './data/firebase';
import useWindowScroll from "@react-hook/window-scroll";
import SignUp from "./SignUp";
import Contact from "./Contact";
import styled from "styled-components";
import SearchBar from "./search/SearchBar";

const Link = styled.a`
  text-decoration: none;
`;

type Props = {
  landingPage?: boolean;
  contactButtonLabel?: string;
};

function TopBar({ landingPage, contactButtonLabel}: Props) {
  const scrollY = useWindowScroll();
  const [searchText, setSearchText] = useState("");
  const isSignedIn = useSignedIn();
  return (
    <header className={scrollY > 0 ? "header headerScroll" : "header"}>
      <Link href="/">
        <div className="headerLogo">
          <img src="/logo.png" />
          Imports.AI
        </div>
      </Link>
      <div className="headerLinks">
        {landingPage === true ? (
          <>
            <SearchBar
              condensed={true}
              placeholder='Look up HTS codes and tariffs e.g. "power relay" or "8537"'
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onClick={() => {
                navigate('search/' + encodeURIComponent(searchText));
              }}
              keyPressedInInput={(event) => {
                const code = event.keyCode || event.which;
                if (code === 13) {  // Hit enter
                  navigate('search/' + encodeURIComponent(searchText));
                }
              }}
            />
            <a href="#how">How It Works</a>
            <a href="#why">Why Imports.AI</a>
            <a href="#guarantee">Guarantee</a>
          </>
        ) : null}
        {isSignedIn ? <a className="button" href="/profile">My Shipments</a>: <SignUp />}
        <Contact text={contactButtonLabel} />
      </div>
    </header>
  );
}

export default TopBar;
