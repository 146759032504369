import moment from 'moment';

export function getCurrentTimestamp(): number {
  return Number(moment().unix());
}

function prefixZero(value: number) {
  if (value < 10) {
    return '0' + value;
  }
  return value;
}

export function formatDuration(seconds: number): string {
  const d = moment.duration({seconds});
  return `${d.minutes()}:${prefixZero(d.seconds())}`;
}
