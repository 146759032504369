import React, {useState} from "react";
import Dialog from "./components/Dialog";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

type Props = {text?: string};

function Contact({text}: Props) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const handleClickOpen = () => {
    setOpen(true);
  };

  const buttonText = text || 'Contact Us';

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };
  return (
    <>
      <a className="button" onClick={handleClickOpen}>
        {buttonText}
      </a>
      <Dialog selectedValue={selectedValue} open={open} onClose={handleClose} title="Contact Us">
      <List>
      <ListItem button>
        <a
          className="button"
          href={`https://mail.google.com/mail/?view=cm&fs=1&to=help@imports.ai&su=Hello Imports.AI&body=Hi`}
        >
          Gmail
        </a>
        </ListItem>
        <ListItem>
        <a className="button" href="mailto:help@gmail.com">
          Other email
        </a>
        </ListItem>
      </List>
      </Dialog>
    </>
  );
}

export default Contact;
