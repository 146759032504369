import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import CenteredContent from './CenteredContent';

export default function PageSpinner() {
  return (
    <CenteredContent>
      <CircularProgress />
    </CenteredContent>
  );
}
