import algoliasearch from "algoliasearch";
const client = algoliasearch("ORTTDJM56B", "41de649bdcf9002de90ac26b61e8d68b");
// Search 3 things at once: One, the official exported US HTS listings ()
const listingsIndex = client.initIndex("hts_listings");
const valueIndex = client.initIndex("hts_values");
const searchRulingsParams = {
  term: "laptop",
  collection: "ALL",
  pageSize: 100,
  page: 1,
  sortBy: "RELEVANCE",
};

const valueSearchForRulingResults = (
    rulingResults: any[],
    onSearchValueResults?: (results: any[]) => {}
  ) => {

  const hts_codes:string[] = [];
  rulingResults.forEach(element => {
    hts_codes.push(...element.tariffs);
  });

  // Build queries to look up all HTS codes:
  const VALUE_QUERY_PARAMS = {
    restrictSearchableAttributes: ["htsno"],
    disableTypoToleranceOnAttributes: ["htsno"],
    // TODO Exact match only
    hitsPerPage: 1000
  };

  const queries:any[] = [];

  hts_codes.forEach(element => {
    const query = {
      indexName: 'hts_listings',
      query: element,
      params: VALUE_QUERY_PARAMS
    };

    queries.push(query);
  });

  // multipleQueries should count as exactly one
  // search request: https://www.algolia.com/doc/faq/accounts-billing/how-algolia-count-records-and-operation/#counting-search-requests
  client.multipleQueries(queries).then(function (responses) {  // Search only in full_description and in hts_code
    // Combine all the responses and then process
    const combinedValueResults:any[] = [];

    responses.results.forEach(element => {
      combinedValueResults.push(...element.hits);
    });

    onSearchValueResults && onSearchValueResults(combinedValueResults);
  });
}

const search = (
  search: string,
  sort: string,
  onSearch?: () => {},
  onSearchResults?: (results: any[]) => {},
  onSearchValueResults?: (results: any[]) => {},
  onRulingSearchResults?: (results: any[]) => {}
) => {
  onSearch && onSearch();

  listingsIndex.search(search).then(function (responses) {  // Search only in full_description and in hts_code
    // restrictSearchableAttributes: ["htsno"],
    // disableTypoToleranceOnAttributes: ["htsno"],
    onSearchResults && onSearchResults(responses.hits);
  });

  // The CBP's rulings search engine is still probably better than Algolia's for now,
  // so proxy requests through https://github.com/Rob--W/cors-anywhere.git (they don't have CORS)
  var proxyUrl = new URL("https://boiling-badlands-01582.herokuapp.com/");
  var rulingsUrl = new URL("https://rulings.cbp.gov/api/search");
  searchRulingsParams["term"] = search;
  searchRulingsParams["sortBy"] = sort;
  // @ts-ignore
  rulingsUrl.search = new URLSearchParams(searchRulingsParams).toString();

  fetch(proxyUrl.toString() + rulingsUrl.toString())
    .then((response) => response.json())
    .then(
      (data) => {
        onRulingSearchResults && onRulingSearchResults(data.rulings);

        // Should we fetch the value listings here, and wait in the same breath?
        valueSearchForRulingResults(data.rulings, onSearchValueResults);
      }
    );
};

const getSubHeadings = (
  root_htsno: string,
  onSearchResults?: (results: any[]) => {}
) => {
  listingsIndex
    .search(root_htsno, {
      restrictSearchableAttributes: ["htsno"],
      disableTypoToleranceOnAttributes: ["htsno"],
      hitsPerPage: 1000,
    })
    .then(function (responses) {
      const results = responses.hits.filter(
        // @ts-ignore
        (result) => result.htsno.indexOf(root_htsno) === 0
      );
      onSearchResults && onSearchResults(results);
    });
};

const drilldown = (
  htsno: string,
  search: string,
  onFocusResult?: (result: any) => {},
  onSearch?: () => {},
  onSearchResults?: (results: any[]) => {},
  onSearchValueResults?: (results: any[]) => {},
  onRulingSearchResults?: (results: any[]) => {}
) => {
  // Check to see if this is deep enough to
  // Does having 8/10 characters always mean that it works? Is 6 ever enough?
  // For now, look up. If not enough detail, ask people to drill down
  onSearch && onSearch();
  listingsIndex
    .search(htsno, {
      restrictSearchableAttributes: ["htsno"],
      disableTypoToleranceOnAttributes: ["htsno"],
      hitsPerPage: 1000,
    })
    .then(function (responses) {
      // Check if first one has tariff: (It should be the direct match)
      // NOTE: First one logic a little improvised
      // If not, show the search results and people can drill down
      if (responses.nbHits > 1) {
        // You don't have to be in the deepest leaf to have a tariff
        // TODO this can be changed if I re-import, but for now allow this
        // @ts-ignore
        if (responses.hits[0].general.length > 0) {
          onFocusResult && onFocusResult(responses.hits[0]);
        } else {
          onSearchResults && onSearchResults(responses.hits);
          onFocusResult && onFocusResult(responses.hits[0]);
        }
      } else if (responses.nbHits === 1) {
        // Make sure the one result actually has the tariff info. If it
        // doesn't because it's contained in a parent, go to the parent.
        // @ts-ignore
        if (responses.hits[0].general.length > 0) {
          onFocusResult && onFocusResult(responses.hits[0]);
        } else {
          if (htsno.length === 13 || htsno.length === 10) {
            drilldown(
              htsno.substring(0, htsno.length - 3),
              search,
              onFocusResult,
              onSearch,
              onSearchResults,
              onSearchValueResults,
              onRulingSearchResults
            );
          }
        }
      } else {
        // No results means that ironically, the HTS code isn't in the listings database
        // This usually happens for 10 digit (13-char) codes e.g. 1006.30.90.55 is
        // not in the PDF, so we need to remove the last 3 chars to
        // get to 1006.30.90, which has the tariff info
        // see https://github.com/mjhu/importsai/issues/26
        // TODO can this issue also happen for 8 digit/10-char codes? We can also remove the last 3 there.
        if (htsno.length === 13 || htsno.length === 10) {
          drilldown(
            htsno.substring(0, htsno.length - 3),
            search,
            onFocusResult,
            onSearch,
            onSearchResults,
            onSearchValueResults,
            onRulingSearchResults
          );
        }
      }
    });

  valueIndex
    .search(htsno, {
      restrictSearchableAttributes: ["htsno"],
      disableTypoToleranceOnAttributes: ["htsno"],
      hitsPerPage: 1000,
    })
    .then(function (responses) {
      onSearchValueResults && onSearchValueResults(responses.hits);
    });

  // The CBP's rulings search engine is still probably better than Algolia's for now,
  // so proxy requests through https://github.com/Rob--W/cors-anywhere.git (they don't have CORS)
  var proxyUrl = new URL("https://boiling-badlands-01582.herokuapp.com/");
  var rulingsUrl = new URL("https://rulings.cbp.gov/api/search");
  searchRulingsParams["term"] = search;
  // @ts-ignore
  rulingsUrl.search = new URLSearchParams(searchRulingsParams).toString();

  fetch(proxyUrl.toString() + rulingsUrl.toString())
    .then((response) => response.json())
    .then(
      (data) => onRulingSearchResults && onRulingSearchResults(data.rulings)
    );
};

export default {
  search,
  drilldown,
  getSubHeadings,
};
