import React, { useState } from "react";
import HTSView from "../hts/HTSView";
import Dialog from "../components/Dialog";

type Props = {
  value: string;
};

function Comment({ value }: Props) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
  };
  const words = value.split(" ");
  const elements = [];
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const isMatch = Boolean(word.match(/^[\d\.]+/));
    if (isMatch) {
      const hts = word;
      const special = (
        <>
          <a
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleClickOpen}
          >
            {word}
          </a>
          {" "}
          <Dialog
            selectedValue={selectedValue}
            open={open}
            onClose={handleClose}
            title={word}
          >
            <HTSView htsNumber={hts} condensed={true} />
          </Dialog>
        </>
      );
      elements.push(special);
    } else {
      elements.push(<span>{word + " "}</span>);
    }
  }
  return <div
    style={{
      backgroundColor: "#9f54aa36",
      borderRadius: "4px",
      padding: "4px",
      marginBottom: "4px",
      marginTop: "4px",
    }}>
   {elements}</div>;
}

export default Comment;
