export default {
  A: ['AF', 'AR', 'AL', 'DZ', 'AO', 'AM', 'AZ', 'BZ', 'BJ', 'BT', 'BO', 'BA', 'BW', 'BR', 'BF', 'MM', 'CM', 'CV', 'CF', 'TD', 'KM', 'CD', 'CG', 'CI', 'DJ', 'DM', 'EC', 'EG', 'ER', 'SZ', 'ET', 'FJ', 'GA', 'GM', 'GE', 'GH', 'GD', 'GN', 'GW', 'GY', 'HT', 'ID', 'IQ', 'JM', 'JO', 'KZ', 'KE', 'KI', 'KV', 'KG', 'LB', 'LS', 'LR', 'MG', 'MW', 'MV', 'ML', 'MR', 'MU', 'MD', 'MN', 'ME', 'MZ', 'NA', 'NP', 'NE', 'NG', 'MK', 'PK', 'PG', 'PY', 'PH', 'RW', 'LC', 'VC', 'WS', 'ST', 'SN', 'RS', 'SL', 'SB', 'SO', 'ZA', 'SD', 'LK', 'SR', 'TZ', 'TH', 'TL', 'TG', 'TO', 'TN', 'TV', 'UG', 'UA', 'UZ', 'VU', 'YE', 'ZM', 'ZW', 'AI', 'IO', 'CX', 'CC', 'CK', 'FK', 'HM', 'MS', 'NU', 'NF', 'PN', 'SH', 'TK', 'VG', 'WF', 'PS', 'EH'],
  AU: ['AU'],
  "A*": ['AF', 'AR', 'AL', 'DZ', 'AO', 'AM', 'AZ', 'BZ', 'BJ', 'BT', 'BO', 'BA', 'BW', 'BR', 'BF', 'MM', 'CM', 'CV', 'CF', 'TD', 'KM', 'CD', 'CG', 'CI', 'DJ', 'DM', 'EC', 'EG', 'ER', 'SZ', 'ET', 'FJ', 'GA', 'GM', 'GE', 'GH', 'GD', 'GN', 'GW', 'GY', 'HT', 'ID', 'IQ', 'JM', 'JO', 'KZ', 'KE', 'KI', 'KV', 'KG', 'LB', 'LS', 'LR', 'MG', 'MW', 'MV', 'ML', 'MR', 'MU', 'MD', 'MN', 'ME', 'MZ', 'NA', 'NP', 'NE', 'NG', 'MK', 'PK', 'PG', 'PY', 'PH', 'RW', 'LC', 'VC', 'WS', 'ST', 'SN', 'RS', 'SL', 'SB', 'SO', 'ZA', 'SD', 'LK', 'SR', 'TZ', 'TH', 'TL', 'TG', 'TO', 'TN', 'TV', 'UG', 'UA', 'UZ', 'VU', 'YE', 'ZM', 'ZW', 'AI', 'IO', 'CX', 'CC', 'CK', 'FK', 'HM', 'MS', 'NU', 'NF', 'PN', 'SH', 'TK', 'VG', 'WF', 'PS', 'EH'],
  "A+": ['AF', 'AO', 'BJ', 'BT', 'BF', 'MM', 'BI', 'KH', 'CF', 'TD', 'KM', 'CD', 'DJ', 'ET', 'GM', 'GN', 'GW', 'HT', 'KI', 'LS', 'LR', 'MG', 'MW', 'ML', 'MR', 'MZ', 'NP', 'NE', 'RW', 'WS', 'ST', 'SN', 'SL', 'SB', 'SO', 'SD', 'TZ', 'TL', 'TG', 'TV', 'UG', 'VU', 'YE', 'ZM'],
  B: [],  // Outdated, removed
  BH: ['BH'],
  C: ['AL', 'CA', 'GE', 'JP', 'MO', 'CN', 'ME', 'NO', 'CH', 'TW', 'GB', 'EG', 'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE'],
  CA: ['CA'],
  CO: ['CO'],
  CL: ['CL'],
  D: ['AO', 'BJ', 'BW', 'BF', 'CV', 'TD', 'KM', 'CG', 'CI', 'DJ', 'ET', 'GA', 'GH', 'GN', 'GW', 'KE', 'LS', 'LR', 'MG', 'MW', 'ML', 'MU', 'MZ', 'NA', 'NG', 'RW', 'ST', 'SN', 'SL', 'ZA', 'SZ', 'TZ', 'TG', 'UG', 'ZM'],
  E: ['AG', 'AW', 'BS', 'BB', 'BZ', 'VG', 'CW', 'DM', 'GD', 'GY', 'HT', 'JM', 'MS', 'KN', 'LC', 'VC', 'TT'],
  "E*": ['AG', 'AW', 'BS', 'BB', 'BZ', 'VG', 'CW', 'DM', 'GD', 'GY', 'HT', 'JM', 'MS', 'KN', 'LC', 'VC', 'TT'],
  IL: ['IL'],
  J: [],
  "J*": [],
  "J+": [],
  JO: ['JO'],
  K: ['CA', 'BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'IE', 'EL', 'ES', 'FR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE', 'GB', 'JP', 'NO', 'CH', 'MO'],
  KR: ['KR'],
  L: ['UY'],
  MA: ['MA'],
  MX: ['MX'],
  OM: ['OM'],
  P: ['CR', 'DO', 'SV', 'GT', 'HN', 'NI'],
  "P+": ['CR', 'DO', 'SV', 'GT', 'HN', 'NI'],
  PA: ['PA'],
  PE: ['PE'],
  R: ['BB', 'BZ', 'CW', 'GY', 'HT', 'JM', 'LC', 'TT'],
  S: ['S'],
  SG: ['SG'],
};
