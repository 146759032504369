import React from "react";
import { useCallback, useEffect, useState } from "react";
import {
  updateShipment,
  isUserInitDone,
  getCurrentUser,
  getUserAccount,
  useSignedIn,
  useID,
  createShipment,
  useShipment,
  createDocumentThread,
  subscribeToDocumentThreads,
  getAnalytics,
} from "../data/firebase";
import { getCurrentTimestamp } from "../Time";
import Button from "../components/Button";
import PageSpinner from "../components/PageSpinner";
import Text from "../components/Text";
import { navigate, useLocation } from "@reach/router";
import styled from "styled-components";
import TopBar from "../TopBar";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import DocumentThread from "./DocumentThread";

const Content = styled.div`
  margin: 100px auto;
  max-width: 70ch;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  align-items: stretch;
  flex-direction: column;
`;

type Props = {
  shipmentID: string;
};

export default function Shipment({ shipmentID }: Props): React.ReactNode {
  const location = useLocation();
  const shipment = useShipment(shipmentID);
  const [shipmentData, setShipmentData] = useState({});
  useEffect(() => setShipmentData(shipment), [shipment]);
  const [isSaving, setIsSaving] = useState(false);
  const next_param = location.search.split("?next=");
  const [userID, _] = useID();
  let next = "";
  if (next_param.length === 2) {
    next = next_param[1];
  }
  const isSignedIn = useSignedIn();
  // @ts-ignore
  const title = (shipmentData || {}).title;
  // @ts-ignore
  const description = (shipmentData || {}).description;
  // @ts-ignore
  const deadline = (shipmentData || {}).deadline;
  const updateShipmentData = useCallback(
    (field: string, event: any) => {
      setShipmentData((prevState) => ({
        ...prevState,
        [field]: event.target.value,
      }));
    },
    [title, description, deadline]
  );
  useEffect(() => {
    if (isSignedIn) {
      navigate(next);
    }
  }, [isSignedIn, next]);
  const [documentThreads, setDocumentThreads] = useState([] as any[]);
  useEffect(() => {
    // @ts-ignore
    subscribeToDocumentThreads(shipmentID, (data: any) => {
      const items = [] as any[];
      Object.keys(data.val() || {}).forEach((key) => {
        items.push({
          id: key,
          ...data.val()[key],
        });
      });
      items.sort(
        (a: any, b: any) => Number(b.creationTime) - Number(a.creationTime)
      );
      setDocumentThreads(items);
    });
  }, [shipmentID]);
  const user = getCurrentUser();
  if (!isUserInitDone()) {
    return <PageSpinner />;
  }

  const name = String((user && user.displayName) || "Guest");
  if (!isSignedIn) {
    navigate("/login/?next=" + location.href);
  }
  return (
    <>
      <TopBar />
      <Content>
        <Card>
          <CardHeader title="Shipment Details" />
          <CardContent>
            <div className="shipmentDetails">
              <TextField
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => updateShipmentData("title", e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Description"
                variant="outlined"
                value={description}
                onChange={(e) => updateShipmentData("description", e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="Target Date"
                type="date"
                variant="outlined"
                value={deadline}
                onChange={(e) => updateShipmentData("deadline", e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </CardContent>
          <CardActions className="shipmentDetailsActions">
            <a
              className="button"
              onClick={async () => {
                await updateShipment(shipmentID, shipmentData);
              }}
            >
              Save
            </a>
          </CardActions>
        </Card>
      </Content>
      <Text style={{ marginBottom: 12 }}>Here are your Documents:</Text>
      {documentThreads.map((thread) => (
        <DocumentThread
          key={thread.id}
          id={thread.id}
          creationTime={thread.creationTime}
          comments={thread.comments}
          status={thread.status || "In Progress"}
          title={thread.title}
          downloadURL={thread.downloadURL}
        />
      ))}
      <Button
        label="Add new document"
        onClick={async () => {
          getAnalytics().logEvent("createDocumentThread", {shipmentID});
          await createDocumentThread(
            {
              owner: userID,
              creationTime: getCurrentTimestamp(),
              shipmentID: shipmentID,
              status: "In Progress",
            },
            // @ts-ignore
            (key) => {}
          );
        }}
      />
    </>
  );
}
