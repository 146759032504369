import React, { useState, useCallback, useEffect } from "react";
import { getAnalytics } from "./data/firebase";
import ResultDetails from "./search/ResultDetails";
import SearchBar from "./search/SearchBar";
import TopBar from "./TopBar";
import ResultList from "./search/ResultList";
import ResultListCard from "./search/ResultListCard";
import ResultsBreadcrumbs from "./search/ResultsBreadcrumbs";
import SearchUtils from "./data/SearchUtils";
import HTSTree from "./hts/HTSTree";
import Loading from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Cookies from 'universal-cookie';
import {PayPalButton} from "react-paypal-button-v2";
import { navigate } from "@reach/router"

const htsMetaData = {};

export function getMonetaryValue(htsno) {
  // Try to get most specific hts value, going up headers
  let value = 0;
  let heading = htsno;
  while (heading && value === 0) {
    const check = htsMetaData[heading];
    if (check) {
      value = check["value"];
    }
    heading = heading.split(".").slice(0, -1).join(".");
  }
  return value === 0 ? "" : String(value);
}

export function getHTSNumberFromRuling(result) {
  return result["tariffs"][0] && result["tariffs"][0].length === 12
    ? result["tariffs"][0].slice(0, 10) + "." + result["tariffs"][0].slice(10)
    : result["tariffs"][0];
}

function SearchView({ query, version }) {
  // Just do one paywall version. KISS
  const paywall = true;
  const MAX_FREE_SEARCHES = 10;

  const [searchText, setSearchText] = useState(
    query ? decodeURIComponent(query) : ""
  );
  const [searchResults, setSearchResults] = useState(null);
  const [valueSearchResults, setValueSearchResults] = useState(null);
  const [rulingSearchResults, setRulingSearchResults] = useState(null);
  const [focusResult, setFocusResult] = useState(null);
  const [focusHTSNumber, setFocusHTSNumber] = useState("");
  const [sort, setSort] = useState("DATE_DESC");
  const [drilldownHTSNumbers, setDrilldownHTSNumbers] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);

  const cookies = new Cookies();
  const soFar = parseInt(cookies.get('numSearchesDone'));
  const paid = cookies.get('paid2020_08_19package_10');
  console.log('984d000403fec50936c2804467591c' + soFar);

  const doFetch = useCallback(
    (newSort, newSearchText) => {
      // Increment usage for paywall limit
      if (soFar) {
        cookies.set('numSearchesDone', soFar + 1, { path: '/' });
      } else {
        cookies.set('numSearchesDone', 1, { path: '/' });
      }

      newSort = newSort || sort;
      newSearchText = newSearchText || searchText;
      setSearchResults(null);
      setValueSearchResults(null);
      setRulingSearchResults(null);
      setHasSearched(true);

      getAnalytics().logEvent("search", { search_term: newSearchText });

      SearchUtils.search(
        newSearchText,
        newSort === "MONETARY" ? "RELAVANCE" : newSort,
        () => {
          setFocusResult(null);
          setDrilldownHTSNumbers([]);
        },
        (results) => {
          setSearchResults(results);
        },
        (values) => {
          // Value data is stored separately
          values.forEach((result) => {
            htsMetaData[result["htsno"]] = {
              commodity: result["commodity"],
              value: result["customs_value"]
                ? result["customs_value"].toLocaleString()
                : "",
            };
          });

          setValueSearchResults(values);
        },
        (rulings) => {
          if (newSort === "MONETARY") {
            rulings.sort((a, b) => {
              const valueA = getMonetaryValue(getHTSNumberFromRuling(a));
              const valueB = getMonetaryValue(getHTSNumberFromRuling(b));
              return (
                Number(valueB.replace(/,/g, "")) -
                Number(valueA.replace(/,/g, ""))
              );
            });
          }
          setRulingSearchResults(rulings);
        }
      );
    },
    [searchText, sort]
  );

  const keyPressedInInput = (event) => {
    setDrilldownHTSNumbers([]);
    var code = event.keyCode || event.which;
    if (code === 13) {
      // Hit enter
      doFetch();
    }
  };

  const drilldown = (htsno) => {
    SearchUtils.drilldown(
      htsno,
      searchText,
      (result) => {
        setFocusResult(result);
      },
      () => {
        const levels = drilldownHTSNumbers;
        levels.push(htsno);
        setDrilldownHTSNumbers(levels);
      },
      (results) => {
        setSearchResults(results);
      },
      (values) => {
        setValueSearchResults(values);
      },
      (rulings) => {
        setRulingSearchResults(rulings);
      }
    );
  };

  const renderExactHTSResults = () => {
    if (!searchResults || !searchText) {
      return null;
    }
    let exactMatches = searchResults;
    if (Number(searchText.replace(/\./g, "")) > 0) {
      // only check for exact match if using hts codes
      exactMatches = searchResults.filter(
        (result) => result.htsno.indexOf(searchText) === 0
      );
    }
    const exactMatch = exactMatches && exactMatches[0];
    if (!exactMatch) {
      return null;
    }
    return (
      <ResultListCard
        key={"resultCard-" + exactMatch.htsno}
        type="raw_hts"
        onClick={(e) => clickResult("raw_hts", exactMatch.htsno)}
        htsNumber={exactMatch.htsno}
        description={exactMatch.full_description}
        rulingNumber=""
      />
    );
  };

  const clickResult = (type, htsNumber) => {
    setFocusHTSNumber(htsNumber);
    getAnalytics().logEvent("select_content", {
      content_type: type,
      item_id: htsNumber,
    });
  };

  const renderRulingSearchResults = () => {
    if (rulingSearchResults === null) {
      return hasSearched ? <Loading /> : null;
    } else if (rulingSearchResults.length === 0) {
      return (
        <span>
          <b>No results.</b> Consider making your search term shorter,
          generalizing, or changing punctuation.
        </span>
      );
    } else {
      /* TODO: Skip over revoked rulings e.g. "revokedBy":[], consider all fields like relatedRulings and modifiedBy */
      return (
        <ResultList
          results={rulingSearchResults}
          clickResult={clickResult}
          limit={5}
        />
      );
    }
  };

  useEffect(() => {
    if (paid) {  // Re-navigate paid users to magic paid URL
      navigate('/searchCXRfBTCEzHF/');
    }
  }, []);


  const paymentSuccess = (details, data) => {
    navigate('/searchCXRfBTCEzHF/');
    cookies.set('paid2020_08_19package_10', true, { path: '/' });
  };

  // Search on first load, if linked here from front page
  if (query && !hasSearched) {
    doFetch();
  }

  // Block out users except if they've paid. Everyone is paywalled
  if (version !== 'CXRfBTCEzHF' && soFar && soFar > MAX_FREE_SEARCHES) {
    return (
      <div className="SearchView">
        <header>
          <TopBar contactButtonLabel="Need Help? Contact Us" />
        </header>
        <div className="section">
          <h2>You have used your limit of free searches! Get unlimited searches for $9.95/month.</h2>
          <div>
            <PayPalButton
              amount={'9.95'}
              currency={'USD'}
              onSuccess={(details, data) => paymentSuccess(details, data)}
              options={{clientId: 'AeFuCmwt5DG5Z2MKZa7gRX2dfxRQpSxMTWfWt-s3F8vq58muySAILskf2PMEpjVp3hzJ54edl8EH-REJ'}}
            />
          </div>
        </div>
      </div>
      );
  }

  return (
    <div className="SearchView">
      <header>
        <TopBar contactButtonLabel="Need Help? Contact Us" />
      </header>

      <div className="section">
        <div className="searchSection">
          <SearchBar
            value={searchText}
            onChange={(e) => {
              /* We shouldn't search on every change... ? */
              setFocusResult(null);
              setFocusHTSNumber("");
              setDrilldownHTSNumbers([]);
              setSearchText(e.target.value);
            }}
            keyPressedInInput={keyPressedInInput}
            onClick={doFetch}
            onClickSuggestion={(value) => {
              setSearchText(value);
              doFetch(sort, value);
            }}
          />
        </div>

        <div className="searchResults">
          <div className="leftResults">
            <h2>
              Results
              <Select
                style={{ marginLeft: 8 }}
                labelId="sort"
                name="Sort"
                defaultValue="DATE_DESC"
                value={sort}
                onChange={(event) => {
                  setSort(String(event.target.value));
                  doFetch(String(event.target.value));
                }}
              >
                <MenuItem value={"RELEVANCE"}>Relevance</MenuItem>
                <MenuItem value={"DATE_DESC"}>Recency</MenuItem>
                <MenuItem value={"MONETARY"}>Total Value</MenuItem>
              </Select>
            </h2>
            {renderExactHTSResults()}
            {renderRulingSearchResults()}
          </div>
          <div className="mainResults">
            <h2>Details</h2>
            {focusHTSNumber && (
              <p>
                <b>How to read a result:</b> HTS code: Description: &#40;$2.1M -
                total value imported to US in May 2020&#41;
              </p>
            )}
            {focusHTSNumber && <HTSTree htsNumber={focusHTSNumber} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchView;
