import React from 'react';
import styled from 'styled-components';

const TextElement = styled.div`
  font-size: 36px;
  color: #1c1e21;
`;

type Props = {
  style?: any;
  children: any;
};

export default function Text({style, children}: Props) {
  return (
    <TextElement style={style}>
      {children}
    </TextElement>
  );
}
