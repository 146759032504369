import React, { useState, useEffect } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ResultListCard from "./ResultListCard";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { getMonetaryValue, getHTSNumberFromRuling } from "../SearchView";
import { isLongestPrefix } from "../hts/HTSTree";
import SearchUtils from "../data/SearchUtils";

type PropsInternal = {
  isFirst: boolean;
  heading: string;
  results: any[];
  clickResult: (type: string, hstNumber: string) => {};
  limit: number;
};

type Props = {
  limit: number;
  results: any[];
  clickResult: (type: string, hstNumber: string) => {};
};

function ResultListInternal({
  isFirst,
  heading,
  results,
  clickResult,
  limit,
}: PropsInternal) {
  const [description, setDescription] = useState("");
  useEffect(() => {
    SearchUtils.getSubHeadings(
      heading,
      // @ts-ignore
      (results) => {
        const result = results.filter((result) => result.htsno === heading)[0];
        if (result) {
          setDescription(result["full_description"]);
        }
      }
    );
  }, [heading]);

  return (
    <>
      <Accordion defaultExpanded={isFirst} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            {heading}: {description}
          </Typography>
        </AccordionSummary>
        {results.slice(0, limit).map((result, index) => {
          const htsno = getHTSNumberFromRuling(result);
          return (
            <ResultListCard
              key={result["rulingNumber"]}
              type="ruling"
              onClick={() => clickResult("ruling", result.tariffs[0])}
              htsNumber={htsno}
              description={result["subject"]}
              rulingNumber={result["rulingNumber"]}
              rulingDate={result["rulingDate"].split("T")[0]}
              htsMonetaryValue={getMonetaryValue(htsno)}
            />
          );
        })}
      </Accordion>
    </>
  );
}

export default function ResultList({ limit, results, clickResult }: Props) {
  const headingSeen = {};
  const headings = [] as string[];
  results.forEach((result) => {
    const htsno = getHTSNumberFromRuling(result);
    if (!htsno) {
      return;
    }
    const root = htsno.split(".")[0];
    if (!headingSeen[root]) {
      headings.push(root);
      headingSeen[root] = true;
    }
  });
  return (
    <>
      {headings.map((heading, index) => (
        <ResultListInternal
          isFirst={index === 0}
          heading={heading}
          key={heading}
          results={results.filter((result) => {
            const htsno = getHTSNumberFromRuling(result);
            return htsno && isLongestPrefix(htsno, heading, []);
          })}
          clickResult={clickResult}
          limit={limit}
        />
      ))}
    </>
  );
}
