import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';

const Wrapper = styled.div`
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  };
`;

type Props = {
  children: any;
  tooltip?: string;
  onClick?: () => void;
};

export default function TooltipWrapper({
  children,
  tooltip,
  onClick
}: Props) {
  return (
    <Tooltip title={tooltip || ''}>
      <Wrapper onClick={onClick}>
        {children}
      </Wrapper>
    </Tooltip>
  );
}
