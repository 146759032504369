export default {
  A:
    "(GSP applies to the tariff number) Afghanistan, Albania, Algeria, Angola, Argentina, Armenia, Azerbaijan, Belize, Benin, Bhutan, Bolivia, Bosnia and Herzegovina, Botswana, Brazil, Burkina Faso, Burma, Burundi, Cambodia, Cameroon, Cape Verde, Central African Republic, Chad, Comoros, Congo (Brazzaville), Congo (Kinshasa), Cote d'Ivoire, Djibouti, Dominica, Ecuador, Egypt, Eritrea, Eswatini, Ethiopia, Fiji, Gabon, The Gambia, Georgia, Ghana, Grenada, Guinea, Guinea-Bissau, Guyana, Haiti, Indonesia, Iraq, Jamaica, Jordan, Kazakhstan, Kenya, Kiribati, Kosovo, Kyrgyzstan, Lebanon, Lesotho, Liberia, Madagascar, Malawi, Maldives, Mali, Mauritania, Mauritius, Moldova, Mongolia, Montenegro, Mozambique, Namibia, Nepal, Niger, Nigeria, North Macedonia, Pakistan, Papua New Guinea, Paraguay, Philippines, Rwanda, Saint Lucia, St. Vincent and the Grenadines, Samoa, Sao Tome and Principe, Senegal, Serbia, Sierra Leone, Solomon Islands, Somalia, South Africa, South Sudan, Sri Lanka, Suriname, Tanzania, Thailand, Timor-Leste, Togo, Tonga, Tunisia, Tuvalu, Uganda, Ukraine, Uzbekistan, Vanuatu, Yemen (Republic of), Zambia, Zimbabwe, Anguilla, British Indian Ocean Territory, Christmas Island, Cocos (Keeling) Islands, Cook Islands, Falkland Islands (Islas Malvinas), Heard Island and McDonald Islands, Montserrat, Niue, Norfolk Island, Pitcarin Islands, Saint Helena, Tokelau, British Virgin Islands, Wallis and Futana, West Bank and Gaza Strip, Western Sahara",
  AU: "Australia",
  "A*":
    "(Certain countries are excluded from GSP eligibility see https://ustr.gov/sites/default/files/IssueAreas/gsp/GSP_Guidebook-December_2019.pdf) but otherwise includes Afghanistan, Albania, Algeria, Angola, Argentina, Armenia, Azerbaijan, Belize, Benin, Bhutan, Bolivia, Bosnia and Herzegovina, Botswana, Brazil, Burkina Faso, Burma, Burundi, Cambodia, Cameroon, Cape Verde, Central African Republic, Chad, Comoros, Congo (Brazzaville), Congo (Kinshasa), Cote d'Ivoire, Djibouti, Dominica, Ecuador, Egypt, Eritrea, Eswatini, Ethiopia, Fiji, Gabon, The Gambia, Georgia, Ghana, Grenada, Guinea, Guinea-Bissau, Guyana, Haiti, Indonesia, Iraq, Jamaica, Jordan, Kazakhstan, Kenya, Kiribati, Kosovo, Kyrgyzstan, Lebanon, Lesotho, Liberia, Madagascar, Malawi, Maldives, Mali, Mauritania, Mauritius, Moldova, Mongolia, Montenegro, Mozambique, Namibia, Nepal, Niger, Nigeria, North Macedonia, Pakistan, Papua New Guinea, Paraguay, Philippines, Rwanda, Saint Lucia, St. Vincent and the Grenadines, Samoa, Sao Tome and Principe, Senegal, Serbia, Sierra Leone, Solomon Islands, Somalia, South Africa, South Sudan, Sri Lanka, Suriname, Tanzania, Thailand, Timor-Leste, Togo, Tonga, Tunisia, Tuvalu, Uganda, Ukraine, Uzbekistan, Vanuatu, Yemen (Republic of), Zambia, Zimbabwe, Anguilla, British Indian Ocean Territory, Christmas Island, Cocos (Keeling) Islands, Cook Islands, Falkland Islands (Islas Malvinas), Heard Island and McDonald Islands, Montserrat, Niue, Norfolk Island, Pitcarin Islands, Saint Helena, Tokelau, British Virgin Islands, Wallis and Futana, West Bank and Gaza Strip, Western Sahara",
  "A+":
    "(GSP Benefits for Least Developed Countries): Afghanistan, Angola, Benin, Bhutan, Burkina Faso, Burma, Burundi, Cambodia, Central African Republic, Chad, Comoros, Congo (Kinshasa / Democratic Republic of the Congo), Djibouti, Ethiopia, The Gambia, Guinea, Guinea-Bissau, Haiti, Kiribati, Lesotho, Liberia, Madagascar, Malawi, Mali, Mauritania, Mozambique, Nepal, Niger, Rwanda, Samoa, Sao Tomé and Principe, Senegal, Sierra Leone, Solomon Islands, Somalia, South Sudan, Tanzania, Timor-Leste, Togo, Tuvalu, Uganda, Vanuatu, Yemen (Republic of), Zambia",
  B: "[ended August 2019] Automotive Product Trade Act (APTA) applies https://www.lexology.com/library/detail.aspx?g=7949bde4-ee61-423b-9494-f8710858c8df",
  BH: "Bahrain",
  C: "Agreement on Trade in Civil Aircraft applies to the tariff number: Albania; Canada; Egypt; the European Union (the following 19 EU member states are also signatories in their own right: Austria; Belgium; Bulgaria; Denmark; Estonia; France; Germany; Greece; Ireland; Italy; Latvia; Lithuania; Luxembourg; Malta; the Netherlands; Portugal; Romania; Spain and Sweden); Georgia; Japan; Macao, China; Montenegro; Norway; Switzerland; Chinese Taipei; the United Kingdom and the United States. https://www.wto.org/english/tratop_e/civair_e/civair_e.htm",
  CA: "Canada",
  CO: "Columbia",
  CL: "Chile",
  D: "African Growth and Opportunity Act: Angola, Benin, Botswana, Burkina Faso, Cape Verde, Chad, Comores, Congo (Republic), Cote d'Ivoire, Djibouti, Ethiopia, Gabon, Ghana, Guinea, Guinea-Bissau, Kenya, Lesotho, Liberia, Madagascar, Malawi, Mali, Mauritius, Mozambique, Namibia, Nigeria, Rwanda, Sao Tome and Principe, Senegal, Sierra Leone, South Africa, Swaziland (Eswatini), Tanzania, Togo, Uganda, Zambia https://agoa.info/about-agoa/country-eligibility.html",
  E: "Caribbean Basin Initiative (CBI) applies to the tariff number: Antigua and Barbuda, Aruba, The Bahamas, Barbados, Belize, British Virgin Islands, Curacao, Dominica, Grenada, Guyana, Haiti, Jamaica, Montserrat, St. Kitts and Nevis, St. Lucia, St. Vincent and the Grenadines, Trinidad and Tobago",
  "E*": "Certain articles are excluded from CBI eligibility - Antigua and Barbuda, Aruba, The Bahamas, Barbados, Belize, British Virgin Islands, Curacao, Dominica, Grenada, Guyana, Haiti, Jamaica, Montserrat, St. Kitts and Nevis, St. Lucia, St. Vincent and the Grenadines, Trinidad and Tobago - see http://www.sice.oas.org/TPD/USA_CBI/Studies/USITCcbi2000_e.pdf",
  IL: "Israel",
  J: "[ended 2013] (Andean Trade Preference Act) Bolivia, Colombia, Ecuador, and Peru",
  "J*": "[ended 2013] (Andean Trade Preference Act) Bolivia, Colombia, Ecuador, and Peru - Certain articles are excluded from ATPA eligibility.",
  "J+": "[ended 2013] (Andean Trade Promotion and Drug Eradication Act) Bolivia, Colombia, Ecuador, and Peru",
  JO: "Jordan",
  K: "(Agreement on Trade in Pharmaceutical Products) Canada, 27 EU Member States and the UK, Japan, Norway, Switzerland, USA, Macau",
  KR: "South Korea",
  L: "Uruguay Round Concessions on Intermediate Chemicals for Dyes.",
  MA: "Morocco",
  MX: "Mexico",
  OM: "Oman",
  P: "(Originating CAFTA Claim wholly obtained or produced entirely in) Costa Rica, Dominican Republic, El Salvador, Guatemala, Honduras, Nicaragua",
  "P+": "(Qualifying CAFTA Claim)  Costa Rica, Dominican Republic, El Salvador, Guatemala, Honduras, Nicaragua",
  PA: "Panama",
  PE: "Peru",
  R: "(Caribbean Basin Partnership Act) Barbados, Belize, Curacao, Guyana, Haiti, Jamaica, Saint Lucia, and Trinidad and Tobago",
  S: "Code S - unknown",
  SG: "Singapore",
};
