import React, { useState } from "react";
import TopBar from "./TopBar";
import SignUp from "./SignUp";
import Contact from "./Contact";
import SearchBar from "./search/SearchBar";
import { navigate } from "@reach/router";
import {
  getAnalytics,
} from "./data/firebase";

function Home() {
  const [searchText, setSearchText] = useState("");
  getAnalytics().logEvent('home_page');
  return (
    <div className="Home">
      <TopBar landingPage={true} />
      <div className="hero">
        <video autoPlay muted loop playsInline className="heroVideo">
          <source src="video.mp4" type="video/mp4" />
        </video>
        <div className="heroOverlay">
          <span>
            Fully automated HTS classification using artificial intelligence
          </span>
          <Contact />
        </div>
      </div>
      <div className="section">
        <div className="sectionHeader" id="how">
          How It Works
        </div>
        <div className="howItWorks">
          <div className="howItWorksImage"></div>
          <span>
            Imports.ai provides instant, fully automated HTS classification for any commodity via cutting-edge machine learning.
            <br /><br />Benefits include increased speed, reduced cost, better consistency, scalability, and learning over time.
          </span>
        </div>
      </div>

      <div className="section">
        <div className="sectionHeader" id="why">
          Why Imports.AI
        </div>
        <div className="bulletList">
          <div className="bullet">
            <img src="brain.png" />{" "}
            <div>
              <strong>Training Process</strong>
              Train once (~2 weeks), run forever. No special skill to deploy or run. 1-2 seconds per classification. Precise and consistent output. Classification gets consistently better over time. Highly scalable - classify as much or as little as needed.
            </div>{" "}
          </div>
          <div className="bullet">
            <img src="scanner.png" />{" "}
            <div>
              <strong>How it Works</strong>
              You have a database, or other parts of a database, with missing or uncertain HTS codes. We'll need examples of correctly classified items. Our system learns from these correctly classified items and will fill in missing classifications for the rest of the database.
            </div>{" "}
          </div>
          <div className="bullet">
            <img src="shield.png" />
            <div>
              <strong>Quality and compliance</strong>
              Our system will pass a confidence score back to your ERP. If desired, you can set a threshold for automated acceptance and shunt everything that doesn’t meet that threshold for manual classification. Since our solution is trained on your data, it’s guaranteed to be consistent with your existing classification history.
            </div>
          </div>
        </div>
      </div>
      <div className="contactUs">
        <div className="sectionHeader">Ready to get started?</div>
        <SignUp />
      </div>
    </div>
  );
}

export default Home;
