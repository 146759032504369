import React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

const Wrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 16px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type Props = {
  title?: string | null;
  rightTitle?: any;
  children: any;
  marginTop?: string;
  marginBottom?: string;
};

export default function Card({
  children,
  title,
  marginTop,
  marginBottom,
  rightTitle,
}: Props) {
  return (
    <Wrapper style={{marginTop, marginBottom}}>
      {title != null ? (
        rightTitle != null ? (
          <Title>
            <Typography variant="h4" component="h4" gutterBottom>
              {title}
            </Typography>
            {rightTitle}
          </Title>
        ) : (
          <Typography variant="h4" component="h4" gutterBottom>
            {title}
          </Typography>
        )
      ) : null}
      {children}
    </Wrapper>
  );
}
