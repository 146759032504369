import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Button from "../components/Button";
import CODE_TO_NAME_ISO_3166 from "../data/constants/CODE_TO_NAME_ISO_3166";
import Contact from "../Contact";
import SearchSuggestions from "./SearchSuggestions";

type Props = {
  value?: string;
  onChange: (e: any) => void;
  keyPressedInInput: (e: any) => void;
  onClick?: () => void;
  onClickSuggestion?: (value: string) => {};
  condensed?: boolean;
  placeholder?: string;
  variant?: "standard" | "filled" | "outlined" | undefined;
  style?: any;
};
export default function SearchBar({
  value,
  onChange,
  keyPressedInInput,
  onClick,
  onClickSuggestion,
  condensed,
  placeholder,
  variant,
  style,
}: Props) {
  return (
    <>
      <TextField
        style={style ?? { marginRight: 16 }}
        placeholder={
          placeholder ||
          'Look up HTS codes and tariffs e.g. "power relay" or "8537"'
        }
        fullWidth={true}
        onChange={onChange}
        variant={variant ??  "standard"}
        value={value}
        onKeyPress={keyPressedInInput}
        autoComplete="off"
      />
      {condensed ? (
        <Button label="Search" onClick={onClick} />
      ) : (
        <div className="searchActions">
          <Button label="Search" onClick={onClick} />
          <SearchSuggestions
            searchText={value}
            // @ts-ignore
            onClick={(value: string) =>
              onClickSuggestion && onClickSuggestion(value)
            }
          />
        </div>
      )}
    </>
  );
}
