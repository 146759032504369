import React, { useState, useEffect } from "react";
import {
  subscribeToShipments,
  isUserInitDone,
  getCurrentUser,
  getUserAccount,
  useSignedIn,
  useID,
  createShipment,
  getAnalytics,
} from "./data/firebase";
import Button from "./components/Button";
import PageSpinner from "./components/PageSpinner";
import Text from "./components/Text";
import { navigate, useLocation } from "@reach/router";
import styled from "styled-components";
import TopBar from "./TopBar";
import { getCurrentTimestamp } from "./Time";
import ShipmentCard from "./shipment/ShipmentCard";

const Content = styled.div`
  margin: 100px auto;
  max-width: 70ch;
`;

const ButtonWrapper = styled.div`
  display: flex;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  align-items: stretch;
  flex-direction: column;
`;

export type ShipmentProps = {
  id: string;
  creationTime: number;
  title: string;
  description: string;
};

export default function Profile(): React.ReactNode {
  const location = useLocation();
  const next_param = location.search.split("?next=");
  const [userID, _] = useID();
  const [shipments, setShipments] = useState([] as ShipmentProps[]);
  useEffect(() => {
    if (!userID) {
      return;
    }
    // @ts-ignore
    subscribeToShipments(userID, (data: any) => {
      const items = [] as ShipmentProps[];
      Object.keys(data.val()).forEach((key) => {
        items.push({
          id: key,
          ...data.val()[key],
        });
      });
      items.sort(
        (a: ShipmentProps, b: ShipmentProps) =>
          Number(b.creationTime) - Number(a.creationTime)
      );
      setShipments(items);
    });
  }, [userID]);
  let next = "";
  if (next_param.length === 2) {
    next = next_param[1];
  }
  const isSignedIn = useSignedIn();
  useEffect(() => {
    if (isSignedIn) {
      navigate(next);
    }
  }, [isSignedIn, next]);
  const user = getCurrentUser();
  if (!isUserInitDone()) {
    return <PageSpinner />;
  }
  const name = String((user && user.displayName) || "Guest");
  if (!isSignedIn) {
    navigate("/signup/?next=" + location.href);
  }
  getAnalytics().logEvent("profile");
  return (
    <>
      <TopBar />
      <Text style={{ marginBottom: 12 }}>{"Profile for " + name}</Text>
      <Text style={{ marginBottom: 12 }}>Here are your shipments:</Text>
      {shipments.map((shipment) => (
        <ShipmentCard
          key={shipment.id}
          id={shipment.id}
          title={shipment.title}
          description={shipment.description}
          creationTime={shipment.creationTime}
        />
      ))}
      <Button
        label="Start New Shipment"
        onClick={async () => {
          getAnalytics().logEvent("createShipment");
          await createShipment(
            {
              owner: userID,
              creationTime: getCurrentTimestamp(),
              documentThreads: [],
            },
            (key) => navigate("/shipment/" + key)
          );
        }}
      />
    </>
  );
}
