import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import StarIcon from '@material-ui/icons/Star';
import FlagIcon from '@material-ui/icons/Flag';
import MapIcon from '@material-ui/icons/Map';
import HelpIcon from '@material-ui/icons/Help';

type Props = {
  status: string;
};

export default function Status({status}: Props) {
  switch (status) {
    case 'Complete':
      return <CheckCircleIcon style={{fill: 'green'}} />;
    case 'Blocked':
      return <CancelIcon style={{fill: 'red'}} />;
    case 'In Process':
      return <StarIcon style={{fill: 'gold'}} />;
    case 'Needs Clarification':
      return <MapIcon style={{fill: 'darkgray'}} />;
    default:
      return <HelpIcon style={{fill: 'blue'}} />;
  }
}
