import React from 'react';
import {useEffect} from 'react';
import {isUserInitDone, getCurrentUser, getUserAccount, useSignedIn} from './data/firebase';
import Button from './components/Button';
import PageSpinner from './components/PageSpinner';
import Text from './components/Text';
import { navigate, useLocation } from "@reach/router"
import styled from 'styled-components';
import TopBar from './TopBar';

const Content = styled.div`
  margin: 100px auto;
  max-width: 70ch;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  align-items: stretch;
  flex-direction: column;
`;

export default function SignIn(): React.ReactNode {
  const location = useLocation();
  const next_param = location.search.split('?next=');
  let next = '';
  if (next_param.length === 2) {
    next = next_param[1];
  }
  const isSignedIn = useSignedIn();
  useEffect(() => {
    if (isSignedIn) {
      navigate(next);
    }
  }, [isSignedIn, next]);
  const user = getCurrentUser();
  if (!isUserInitDone()) {
    return <PageSpinner />;
  }
  const name = String(user && user.displayName || 'Guest');
  return (
    <>
      <TopBar />
      <Content>
        <Text style={{marginBottom: 12, textAlign: 'center'}}>
          {isSignedIn ? "Signed up as " + name : ""}
        </Text>
        {isSignedIn ?
          <Text style={{marginBottom: 12, textAlign: 'center'}}>
            Stay tuned for more information!
          </Text>
        : null}
        {!isSignedIn?
        <ButtonWrapper>
          <Button
            style={{marginBottom: 12}}
            label="continue with Google"
            onClick={async () => {
              getUserAccount('google', next);
            }}
          />
        </ButtonWrapper>
        : null}
      </Content>
    </>
  );
}
