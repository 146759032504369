import React, { useState } from "react";
import Text from "../components/Text";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "../components/Button";
import {
  addComment,
  useID,
  updateDocumentThread,
  uploadFile,
  getAnalytics,
} from "../data/firebase";
import { getCurrentTimestamp } from "../Time";
import Comment from "./Comment";
import MUIButton from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Status from "./Status";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Tooltip from "../components/TooltipWrapper";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

type Props = {
  id: string;
  creationTime: number;
  comments: any[];
  status: string;
  title: string;
  downloadURL: string;
};

const STATUSES = ["In Progress", "Blocked", "Needs Clarification", "Complete"];

function DocumentThread({
  id,
  creationTime,
  comments,
  status,
  title,
  downloadURL,
}: Props) {
  const [userID, _] = useID();
  const [comment, setComment] = useState("");
  const [docTitle, setDocTitle] = useState(title);
  const [focusImage, setFocusImage] = useState(false);
  async function onSubmit() {
    getAnalytics().logEvent("comment", {threadID: id});
    await addComment(id, {
      commenter: userID,
      creationTime: getCurrentTimestamp(),
      comment: comment.trim(),
    });
    setComment("");
  }
  getAnalytics().logEvent("loadDocumentThread", {threadID: id});
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div className="documentThreadHeader">
          <div
            style={{ display: "flex", alignItems: "flex-end" }}
            onClick={(e) => e.stopPropagation()}
          >
            <TextField
              label="Document"
              variant="outlined"
              style={{ width: "500px" }}
              value={docTitle}
              onChange={(e) => setDocTitle(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button
              label="Save"
              onClick={async () => {
                await updateDocumentThread(id, { title: docTitle });
              }}
            />
            <Tooltip tooltip="Upload new document" onClick={() => {}}>
              <CloudUploadIcon />
              <input
                style={{ width: "80px" }}
                type="file"
                name="file"
                onChange={(event) => {
                  if (event) {
                    // @ts-ignore
                    const file = event.target.files[0];
                    uploadFile(id, file, async (downloadURL) => {
                      await updateDocumentThread(id, { downloadURL });
                    });
                  }
                }}
              />
            </Tooltip>
          </div>
          <ButtonGroup>
            {STATUSES.map((s) => (
              <MUIButton
                key={s}
                style={
                  s === status
                    ? { backgroundColor: "#9f54aa", color: "white" }
                    : {}
                }
                startIcon={<Status status={s} />}
                onClick={async (e) => {
                  e.stopPropagation();
                  await updateDocumentThread(id, { status: s });
                }}
              >
                {s}
              </MUIButton>
            ))}
          </ButtonGroup>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <img
            src={downloadURL}
            style={{ width: "750px", cursor: "pointer" }}
            onClick={(e) => {
              console.log("open");
              setFocusImage(true);
            }}
          />
          {focusImage && (
            <Lightbox
              mainSrc={downloadURL}
              onCloseRequest={() => setFocusImage(false)}
            />
          )}
        </div>
        <div>
          {(comments || []).map((comment) => (
            <div style={{
              display: "flex",
              justifyContent: userID === comment.commenter ? 'flex-end' : 'initial',
            }}>
              <Comment value={comment.comment} />
            </div>
          ))}
        </div>
        <div style={{ display: "flex", flexGrow: 1 }}>
          <TextField
            fullWidth
            label="Your Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                onSubmit();
                ev.preventDefault();
              }
            }}
          />
          <Button label="Submit" onClick={onSubmit} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default DocumentThread;
