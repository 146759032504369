import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { getAnalytics } from "../data/firebase";

type Props = {
  searchText?: string;
  onClick: (searchText: string) => {};
};

function SearchSuggestions({ searchText, onClick }: Props) {
  const words = (searchText || "").replace(/[\W_]+/g, " ").split(" ");
  const suggestions = [];
  if (words.length > 2 && words.length <= 5) {
    let prev = words[0];
    for (let i = 1; i < words.length; i++) {
      suggestions.push(prev + " " + words[i]);
      prev = words[i];
    }
  } else {
    return null;
  }
  return (
    <div className="searchSuggestions">
      Not finding what you're looking for? Try these suggestions:
      <Breadcrumbs separator="," aria-label="breadcrumb">
        {suggestions.map((s) => (
          <Link
            style={{cursor: 'pointer'}}
            key={s}
            onClick={(e: any) => {
              onClick && onClick(s);
              getAnalytics().logEvent('click_suggestion', {
                searchText: searchText,
                suggestion: s,
              });
            }}
          >
            {s}
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
}

export default SearchSuggestions;
