import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';

export interface DialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
  title?: string;
  children?: any;
}

function Dialog(props: DialogProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <MuiDialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{props.title}</DialogTitle>
      {props.children}
    </MuiDialog>
  );
}

export default Dialog
