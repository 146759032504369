import React from 'react';
import styled from 'styled-components';
import {Link} from '@reach/router';

const LinkElement = styled(Link)`
  background-color: white;
  border: 1px solid #9f54aa;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  padding: 6px 12px 8px;
  cursor: pointer;
  &:hover {
    background-color: #9f54aa1c;
  }
  text-decoration: none;
  display: inline-block;
  height: 36px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
`;

const ButtonElement = styled.button`
  background-color: white;
  border: 1px solid #9f54aa;
  border-radius: 4px;
  color: #9f54aa;
  font-size: 16px;
  padding: 8px 12px;
  cursor: pointer;
  opacity: 1;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  style?: any;
  label: string;
  onClick?: () => void;
  to?: string;
  isDisabled?: boolean;
};

export default function Button({
  style,
  label,
  to,
  onClick,
  isDisabled,
}: Props) {
  if (to) {
    return (
      <LinkElement to={to} style={style}>
        {label}
      </LinkElement>
    );
  }
  return (
    <ButtonElement
      disabled={isDisabled}
      style={style}
      onClick={onClick}>
      {label}
    </ButtonElement>
  );
}
