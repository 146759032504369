import React from "react";
import Text from "../components/Text";

type Props = {
  id: string;
  creationTime: number;
  title: string;
  description: string;
};

function ShipmentCard({ id, creationTime, title, description}: Props) {
  return (
    <a href={"/shipment/" + id}>
      <Text key={id} style={{ marginBottom: 12 }}>
        {(title || id) + " " + creationTime}
      </Text>
    </a>
  );
}

export default ShipmentCard;
