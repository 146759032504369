import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/analytics";
import "firebase/storage";

import { navigate } from "@reach/router";
import { useState, useEffect } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyDiBCUkutKpFGTJdEFvO-Ipprdm7vzx-sQ",
  authDomain: "importsai.firebaseapp.com",
  databaseURL: "https://importsai.firebaseio.com",
  projectId: "importsai",
  storageBucket: "importsai.appspot.com",
  messagingSenderId: "374260649193",
  appId: "1:374260649193:web:eae3403e9e3146c6377478",
  measurementId: "G-N9ZEY78MTT",
};

firebase.initializeApp(firebaseConfig);

const database = firebase.database();
const storageRef = firebase.storage().ref();

export function getAnalytics(): firebase.analytics.Analytics {
  return firebase.analytics();
}

let USER_INIT_DONE = false;

export function isUserInitDone(): boolean {
  return USER_INIT_DONE;
}

export function useSignedIn(): boolean | null {
  const user = firebase.auth().currentUser;
  const [signedIn, setSignedIn] = useState(user != null ? true : null);
  firebase.auth().onAuthStateChanged(function (user) {
    USER_INIT_DONE = true;
    if (user) {
      // User is signed in.
      setSignedIn(true);
    } else {
      // No user is signed in.
      setSignedIn(false);
    }
  });
  return signedIn;
}

export function getCurrentUser(): firebase.User | null {
  return firebase.auth().currentUser;
}

export function useID(): [string | null, any] {
  const [id, setID] = useState<string | null>(null);
  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      // User is signed in.
      setID(user.uid);
    } else {
      // No user is signed in.
      setID(null);
    }
  });
  return [id, setID];
}

export function getUserAccount(source: string, next: string): void {
  firebase.auth().onAuthStateChanged(function (user) {
    if (user && !user.isAnonymous) {
      // User is signed in.
    } else {
      if (source === "anonymous") {
        firebase
          .auth()
          .signInAnonymously()
          .catch(function (error) {
            console.warn(error);
          });
        navigate(next || "/");
        return;
      }
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          if (user && user.isAnonymous) {
            navigate(next || "/");
          }
        })
        .catch(function (error) {
          console.warn(error);
        });
    }
  });
}

export function getShipmentKey(): string {
  return database.ref().child("shipments").push().key!;
}

export function createShipment(
  value: any,
  onSuccess: (key: string) => {}
): Promise<void> {
  const key = getShipmentKey();
  return new Promise((resolve, reject) => {
    database.ref("shipments/" + key).set(value, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
        onSuccess(key);
      }
    });
  });
}

export function subscribeToShipments(
  id: string,
  callback: (data: any) => {}
): void {
  database
    .ref()
    .child("shipments")
    .orderByChild("owner")
    .equalTo(id)
    .on("value", (snapshot) => {
      if (snapshot) {
        callback(snapshot);
      }
    });
}

export function subscribeToShipment(
  key: string,
  callback: (data: any) => {}
): void {
  database.ref("shipments/" + key).on("value", (snapshot) => {
    callback(snapshot.val());
  });
}

export function useShipment(id: string): any {
  const [shipment, setShipment] = useState(null);

  useEffect(() => {
    // @ts-ignore
    subscribeToShipment(id, setShipment);
  }, [id]);

  return shipment;
}

export function updateShipment(id: string, newShipment: any): Promise<void> {
  return database.ref("shipments/" + id).transaction((shipment) => {
    if (shipment) {
      return { ...shipment, ...newShipment };
    }
    return newShipment;
  });
}

export function getDocumentThreadKey(): string {
  return database.ref().child("documentThreads").push().key!;
}

export function createDocumentThread(
  value: any,
  onSuccess: (key: string) => {}
): Promise<void> {
  const key = getDocumentThreadKey();
  return new Promise((resolve, reject) => {
    database.ref("documentThreads/" + key).set(value, (error) => {
      if (error) {
        reject(error);
      } else {
        resolve();
        onSuccess(key);
      }
    });
  });
}

export function subscribeToDocumentThreads(
  id: string,
  callback: (data: any) => {}
): void {
  database
    .ref()
    .child("documentThreads")
    .orderByChild("shipmentID")
    .equalTo(id)
    .on("value", (snapshot) => {
      if (snapshot) {
        callback(snapshot);
      }
    });
}

export function subscribeToDocumentThread(
  key: string,
  callback: (data: any) => {}
): void {
  database.ref("documentThreads/" + key).on("value", (snapshot) => {
    callback(snapshot.val());
  });
}

export function useDocumentThread(id: string): any {
  const [documentThread, setDocumentThread] = useState(null);

  useEffect(() => {
    // @ts-ignore
    subscribeToDocumentThread(id, setDocumentThread);
  }, [id]);

  return documentThread;
}

export function updateDocumentThread(
  id: string,
  newDocumentThread: any
): Promise<void> {
  return database.ref("documentThreads/" + id).transaction((documentThread) => {
    if (documentThread) {
      return { ...documentThread, ...newDocumentThread };
    }
    return newDocumentThread;
  });
}

export function addComment(id: string, comment: any): Promise<void> {
  return database
    .ref("documentThreads/" + id + "/comments")
    .transaction((comments) => {
      return [...(comments || []), comment];
    });
}

export function uploadFile(
  documentThreadID: string,
  file: File,
  callback: (downloadURL: string) => {}
): void {
  const metadata = {
    contentType: file.type,
    documentThreadID,
  };
  const uploadTask = storageRef
    .child("images/" + file.name)
    .put(file, metadata);
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    function (snapshot) {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          break;
      }
    },
    function (error) {},
    function () {
      uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        callback(downloadURL);
      });
    }
  );
}
