import React, { useState } from "react";
import Card from "../components/Card";
import CODE_TO_NAME_ISO_3166 from "../data/constants/CODE_TO_NAME_ISO_3166";
import SPECIAL_TO_SUMMARY from "../data/constants/SPECIAL_TO_SUMMARY";
import SPECIAL_TO_REGION_CODES from "../data/constants/SPECIAL_TO_REGION_CODES";
import SPECIAL_NOTES from "../data/constants/SPECIAL_NOTES";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

type Props = {
  result: { [key: string]: any };
  standalone?: boolean;
};

// Example: Free (A, AU, BH, CA, CL, CO, D, E, IL, JO, MA, MX, OM, P, PA, PE, S, SG) 4.4% (KR)
// (1006.30.10) -> Convert to human readable string
const expandTariffInfo = (listing: any, importRegion: string) => {
  let rate_string = "";

  // When we expand, we can also build a list of ISO region codes
  let code_to_rate = {};

  // First, set column one countries at regular rate
  Object.keys(CODE_TO_NAME_ISO_3166).forEach((key, i) => {
    code_to_rate[key] = listing.general;
  });

  // Then, set column two countries: KP and CU
  code_to_rate["KP"] = listing.other + " NOTE: Subject to sanctions";
  code_to_rate["CU"] = listing.other + " NOTE: Subject to sanctions";

  // Then fill in special rates.
  const info = listing.special;

  if (info && info.length > 0) {
    let results = [...info.matchAll("([^\\(]+)\\(([^\\)]+)\\)")];

    // Parse each section of the special tariffs and get the rate
    // e.g. Free (Bahrain, Chile, Jordan, Mexico, Oman, Singapore) 5.2¢/kg + 0.6% (South Korea) 10.5¢/kg + 1.2% (Peru) 21.1¢/kg + 2.4% (Columbia, Panama) 26.4¢/kg + 3% ((Originating CAFTA Claim wholly obtained or produced entirely in) Costa Rica, Dominican Republic, El Salvador, Guatemala, Honduras, Nicaragua) See 9912.04.30, 9912.04.47 (Morocco) See 9913.04.25 (Australia) See 9915.04.30, 9915.04.47, 9915.04.71 ((Qualifying CAFTA Claim) Costa Rica, Dominican Republic, El Salvador, Guatemala, Honduras, Nicaragua) See 9823.08.01-9823.08.38 (S+)
    results.forEach((result) => {
      let rate = result[1];
      let origin_codes = result[2].split(",");

      let expanded_origins = [] as string[];

      origin_codes.forEach((code: string) => {
        let trimmed = code.trim();
        if (SPECIAL_TO_SUMMARY.hasOwnProperty(trimmed)) {
          // Special code, needs expansion
          expanded_origins.push(String(SPECIAL_TO_SUMMARY[trimmed]));
          let regions_for_code = SPECIAL_TO_REGION_CODES[trimmed];
          regions_for_code.forEach((code: string) => {
            // Some are subject to restrictions if special code contains *
            if (trimmed.includes("*")) {
              code_to_rate[code] =
                rate +
                " - may be subject to restrictions under " +
                trimmed +
                " see " +
                SPECIAL_NOTES[trimmed];
            } else {
              code_to_rate[code] = rate;
            }
          });
        } else if (CODE_TO_NAME_ISO_3166.hasOwnProperty(trimmed)) {
          expanded_origins.push(String(CODE_TO_NAME_ISO_3166[trimmed]));
          code_to_rate[trimmed] = rate;
        } else {
          expanded_origins.push(String(trimmed));
          code_to_rate[trimmed] = rate;
        }
      });

      rate_string += rate + " (" + expanded_origins.join(", ") + ")\n";
    });
  }

  return [rate_string, code_to_rate[importRegion]];
};

function getSec301Tariffs(result: any, importRegion: string) {
  if (importRegion !== 'CN') {
    return null;
  }

  if (typeof result['sec_301'] === 'undefined' || result['sec_301'] === null) {
    return null;
  }

  // Convert result.sec_301.rate and result_sec_301.date
  // TODO: Use better system: year 2099 for suspended tariffs
  if (result.sec_301.effective === '2099-12-31') {
    return null;
  }

  const percentRate = parseFloat(result.sec_301.rate) * 100;
  const percentRateString = "+" + percentRate.toFixed(2) + '%';

  return percentRateString;
}

function renderSec301Tariffs(result: any, importRegion: string) {
  const rate = getSec301Tariffs(result, importRegion);
  if (rate == null) {
    return null;
  }

  return (
    <TableRow key="sec301Rate">
      <TableCell component="th" scope="row">
        Additional section 301 tariff (China)
      </TableCell>
      <TableCell>{rate}, starting {result.sec_301.effective}</TableCell>
    </TableRow>);

}

function ResultDetails({ result, standalone }: Props) {
  const [importRegion, setImportRegion] = useState("CN");
  if (!result.htsno) {
    return null;
  }



  return (
    <div className="resultSection">
      <Card title={standalone ? "" : "Your tariff result"}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow key="htsno">
                <TableCell component="th" scope="row">
                  <a
                    href={"/hts/" + result.htsno}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {result.htsno}
                  </a>
                </TableCell>
                <TableCell>{result.full_description}</TableCell>
              </TableRow>
              <TableRow key="Units">
                <TableCell component="th" scope="row">
                  Units
                </TableCell>
                <TableCell>{result.units[0]}</TableCell>
              </TableRow>
              <TableRow key="selectedRegion">
                <TableCell component="th" scope="row">
                  Tariff for your selected region {' '}
                  <Select
                    labelId="importRegion"
                    name="importRegion"
                    value={importRegion}
                    onChange={(event) =>
                      setImportRegion &&
                      setImportRegion(String(event.target.value))
                    }
                  >
                    {Object.keys(CODE_TO_NAME_ISO_3166).map((key, i) => (
                      <MenuItem key={key} value={key}>
                        {CODE_TO_NAME_ISO_3166[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  {expandTariffInfo(result, importRegion)[1]} {getSec301Tariffs(result, importRegion) && (getSec301Tariffs(result, importRegion) + ' (Section 301 tariff)')}
                </TableCell>
              </TableRow>
              <TableRow key="tariffRate">
                <TableCell component="th" scope="row">
                  General tariff rate
                </TableCell>
                <TableCell>{result.general}</TableCell>
              </TableRow>
              {renderSec301Tariffs(result, importRegion)}
              <TableRow key="specialTariffRate">
                <TableCell component="th" scope="row">
                  Special favored tariff rates (expanded)
                </TableCell>
                <TableCell>
                  {expandTariffInfo(result, importRegion)[0]}
                </TableCell>
              </TableRow>
              <TableRow key="specialTariffRawRate">
                <TableCell component="th" scope="row">
                  Special favored tariff rates (raw)
                </TableCell>
                <TableCell>{result.special}</TableCell>
              </TableRow>
              <TableRow key="twoColumnTariffRates">
                <TableCell component="th" scope="row">
                  Column 2 tariff rates (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://help.cbp.gov/s/article/Article-310?language=en_US#:~:text=Countries%20not%20covered%20by%20NTR,are%20Cuba%20and%20North%20Korea.&text=You%20may%20also%20refer%20to,HTS%2C%20Sections%203%2D18."
                  >
                    currently North Korea and Cuba
                  </a>
                  )
                </TableCell>
                <TableCell>{result.other}</TableCell>
              </TableRow>
              <TableRow key="note">
                <TableCell component="th" scope="row">
                  Note
                </TableCell>
                <TableCell>
                  Imports may be subject to other sanctions and restrictions.
                  Generally, goods may not be imported from or exported to North
                  Korea or any person or entity named in the{" "}
                  <a href="https://www.treasury.gov/resource-center/sanctions/sdn-list/pages/default.aspx">
                    Specially Designated Nationals (SDN) list
                  </a>
                  . Certain import and/or export restrictions are in place for
                  Cuba, Iran, Iraq, and Syria. For more information see the{" "}
                  <a href="https://www.treasury.gov/about/organizational-structure/offices/pages/office-of-foreign-assets-control.aspx">
                    Office of Foreign Assets Control
                  </a>
                  .
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </div>
  );
}

export default ResultDetails;
