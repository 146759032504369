import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

type Props = {
  children: any;
  style?: any;
};

export default function CenteredContent({children, style}: Props) {
  return <Div style={style}>{children}</Div>;
}
