import React, { useState, useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchUtils from "../data/SearchUtils";
import HTSView from "./HTSView";

type Props = {
  htsNumber: string;
};

type PropsInternal = {
  layer: number;
  htsNumber: string;
  description: string;
  subHeadings: { htsno: string; description: string; indent: number }[];
  pageHTSNumber: string;
  customsValue: number;
};

export function isLongestPrefix(check: string, root: string, rest: string[]): boolean {
  let longest_from_rest = 0;
  for (let i = 0; i < rest.length; i++) {
    if (
      check.indexOf(rest[i]) === 0 &&
      rest[i].length > longest_from_rest &&
      check !== rest[i]
    ) {
      longest_from_rest = rest[i].length;
    }
  }
  const a = check.indexOf(root) === 0 && root.length > longest_from_rest;
  return a;
}

function nFormatter(num: number, digits: number) {
  var suffix = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "K" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "B" },
  ];
  var regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = suffix.length - 1; i > 0; i--) {
    if (num >= suffix[i].value) {
      break;
    }
  }
  return (num / suffix[i].value).toFixed(digits).replace(regex, "$1") + suffix[i].symbol;
}

function displayCustomsValue(num: number) {
  if (typeof(num) === 'undefined' || num === null) {
    return '';
  } else {
    return " (\$" + nFormatter(num, 1) + ")";
  }
}

function HTSTreeInternal({
  layer,
  htsNumber,
  description,
  subHeadings,
  pageHTSNumber,
  customsValue,
}: PropsInternal) {
  if (!subHeadings) {
    return <div> end</div>;
  }
  /*
   const matchingSubHeadings = subHeadings.filter(sub =>
       (sub.htsno.split('.').length === layer +1 && sub.htsno.indexOf(htsNumber) === 0) || sub.indent == layer);
   */
  const prefixes = subHeadings.map((sub) => sub.htsno);
  const matchingSubHeadings = subHeadings.filter((sub) =>
    isLongestPrefix(sub.htsno, htsNumber, prefixes)
  );
  const used = {};
  for (let i = 0; i < matchingSubHeadings.length; i++) {
    used[matchingSubHeadings[i].htsno] = true;
  }
  const nonMatchingSubHeadings = subHeadings.filter(
    (sub) =>
      !used[sub.htsno] &&
      sub.htsno.split(".").length !== htsNumber.split(".").length
  );
  const isFocused = pageHTSNumber.indexOf(htsNumber) === 0;
  return (
    <>
      <Accordion
        className={pageHTSNumber === htsNumber ? "focusedLevel" : ""}
        defaultExpanded={isFocused}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {htsNumber + ": " + description + displayCustomsValue(customsValue)}
        </AccordionSummary>
        <AccordionDetails>
          <div className="">
          <HTSView htsNumber={htsNumber} condensed={true} />
          <div style={{ width: "100%" }}>
            {matchingSubHeadings.map((subHeading) => (
              <HTSTreeInternal
                key={subHeading.htsno}
                layer={layer + 1}
                htsNumber={subHeading.htsno}
                description={subHeading.description}
                subHeadings={nonMatchingSubHeadings}
                pageHTSNumber={pageHTSNumber}
                customsValue={subHeading['customs_value'] || null}
              />
            ))}
          </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

function HTSTree({ htsNumber }: Props) {
  const root = htsNumber.split(".")[0];
  const [focusResult, setFocusResult] = useState<{ description?: string }>({});
  const [subHeadings, setSubHeadings] = useState<
    { htsno: string; description: string; indent: number }[]
  >([]);
  useEffect(() => {
    SearchUtils.getSubHeadings(
      root,
      // @ts-ignore
      (results) => {
        setFocusResult(results.filter((result) => result.htsno === root)[0]);
        setSubHeadings(results.filter((result) => result.htsno !== root));
      }
    );
  }, [root, htsNumber]);
  return (
    <>
      <HTSTreeInternal
        layer={1}
        htsNumber={root}
        description={focusResult ? String(focusResult.description) : ''}
        subHeadings={subHeadings}
        pageHTSNumber={htsNumber}
        customsValue={focusResult ? (focusResult['customs_value'] || null) : null}
      />
    </>
  );
}

export default HTSTree;
