import React from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

type Props = {
  htsNumbers: string[];
  onClick: (htsno: string) => void;
}

function ResultsBreadcrumbs({htsNumbers, onClick}: Props) {
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {htsNumbers.map(htsno => <Link key={htsno} onClick={() => {onClick(htsno)}}>{htsno}</Link>)}
    </Breadcrumbs>
  );
}

export default ResultsBreadcrumbs 
