import React, { useState, useEffect } from "react";
import SearchUtils from "../data/SearchUtils";
import ResultDetails from "../search/ResultDetails";
import TopBar from "../TopBar";
import HTSTree from "./HTSTree";

type Props = {
  htsNumber: string;
  condensed?: boolean;
  importRegion?: string;
};

function HTSView({ htsNumber, condensed, importRegion}: Props) {
  const [focusResult, setFocusResult] = useState({});
  useEffect(() => {
    SearchUtils.drilldown(
      htsNumber,
      "",
      // @ts-ignore
      (result) => {
        setFocusResult(result);
      }
    );
  }, [htsNumber]);
  return (
    <>
      {condensed ? null : <TopBar />}
      <ResultDetails result={focusResult} standalone={true} />
      {condensed ? null : <HTSTree htsNumber={htsNumber} />}
    </>
  );
}

export default HTSView;
