import React from "react";
import "./App.css";

import { Router } from "@reach/router";
import Home from "./Home";
import SearchView from "./SearchView";
import SignIn from "./SignIn";
import Profile from "./Profile";
import Shipment from "./shipment/Shipment";
import HTSView from "./hts/HTSView";

export default function App() {
  return (
    <Router>
      <Home path="/" />
      <SearchView path="search/:query" />
      <SearchView path="search/" />
      <SearchView path="search_t/" />
      <SearchView path="search_d/" />
      <SearchView path="search2/" version="2" />
      <SearchView path="search3/" version="3" />
      <SearchView path="search4/" version="4" />
      <SearchView path="searchCXRfBTCEzHF/" version="CXRfBTCEzHF" />
      <HTSView path="hts/:htsNumber" />
      <SignIn path="/waitlist" />
      <SignIn path="/signup" />
      <Profile path="/profile" />
      <Shipment path="/shipment/:shipmentID" />
    </Router>
  );
}
