import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Tooltip from "../components/TooltipWrapper";
import GavelIcon from "@material-ui/icons/Gavel";
import AccountTreeIcon from "@material-ui/icons/AccountTree";

type Props = {
  type: "ruling" | "raw_hts";
  htsNumber: string;
  description: string;
  rulingNumber: string;
  rulingDate: string;
  onClick?: () => void;
  htsMonetaryValue?: string;
};

function ResultListCard({
  type,
  htsNumber,
  description,
  rulingNumber,
  rulingDate,
  onClick,
  htsMonetaryValue
}: Props) {
  return (
    <Tooltip
      tooltip="Click to see detailed results and filter for sub categories"
      onClick={onClick}
    >
      <Card style={{ marginBottom: 4 }}>
        <CardContent>
          <CardHeader
            style={{marginLeft: -16}}
            avatar={type === "ruling" ? <GavelIcon /> : <AccountTreeIcon />}
            title={htsNumber}
            titleTypographyProps={{component: "h2", variant: "h5"}}
          />
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <Link
            href={"https://rulings.cbp.gov/ruling/" + rulingNumber}
            rel="noopener"
            target="_blank"
          >
            {rulingNumber}
          </Link>
          <Typography variant="body2" color="textSecondary" component="p">
            {rulingDate}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {htsMonetaryValue ? '$'+htsMonetaryValue : ''}
          </Typography>
        </CardActions>
      </Card>
    </Tooltip>
  );
}

export default ResultListCard;
